.header {
  background: $light-background-header;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;

  @media (prefers-color-scheme: dark) {
    background: $dark-background-header;
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media #{$media-size-phone} {
      flex-direction: row-reverse;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 760px;
    max-width: 100%;
  }
}

.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: pointer;
}

.theme-toggler {
  fill: currentColor;
}

.unselectable {
  user-select: none;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
}